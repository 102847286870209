import { DisabledOverlayComponent } from './../components/disabled-overlay/disabled-overlay.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterByPipe } from '../pipes/flter-by-search.pipe';
import { OrderByPipe } from '../pipes/orderBy.pipe';
import { IndexAlphabetPipe } from '../pipes/numbertoalphabet.pipe'
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { LayoutModule } from '@angular/cdk/layout';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSelectModule } from 'ngx-select-ex';
import { FilterPipeLevel } from '../pipes/filter-level.pipe';
import { DragableDirective } from './directives/dragable.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgChartsModule } from 'ng2-charts';
import { PipRoleDirective } from './directives/pip-role.directive';
import { UtcToLocalPipe } from '../pipes/utc-to-local.pipe';
import { ClosedViewDirective } from './directives/closed-view.directive';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { IdpFlagDirective } from './directives/idp-flag.directive';
import { IdpRoleDirective } from './directives/idp-role.directive';
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MMM/YYYY',
  },
  display: {
    dateInput: 'DD/MMM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MMM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    FilterByPipe,
    OrderByPipe,
    IndexAlphabetPipe,
    FilterPipeLevel,
    DragableDirective,
    PipRoleDirective,
    UtcToLocalPipe,
    ClosedViewDirective,
    IdpFlagDirective,
    DisabledOverlayComponent,
    IdpRoleDirective,
  ],
  imports: [
    CommonModule,
    DragDropModule,
    CdkTreeModule,
    CdkStepperModule,
    MatPaginatorModule,
    NgSelectModule,
    NgxPaginationModule,
    NgxSelectModule,
    CdkAccordionModule,
    LayoutModule,
    FormsModule,
    ReactiveFormsModule,
    NgApexchartsModule,
    NgChartsModule,
    SweetAlert2Module,
    PdfViewerModule
  ],
  exports:[
    FilterByPipe,
    OrderByPipe,
    IndexAlphabetPipe,
    CdkTreeModule,
    CdkStepperModule,
    MatPaginatorModule,
    NgSelectModule,
    NgxPaginationModule,
    DragDropModule,
    NgxSelectModule,
    LayoutModule,
    CdkAccordionModule,
    FormsModule,
    ReactiveFormsModule,
    NgApexchartsModule,
    NgChartsModule,
    UtcToLocalPipe,
    PipRoleDirective,
    ClosedViewDirective,
    SweetAlert2Module,
    PdfViewerModule,
    IdpFlagDirective,
    IdpRoleDirective,
    DisabledOverlayComponent,

  
  ],
  providers:    [ FilterByPipe,  FilterPipeLevel,OrderByPipe,
    {
        provide: DateAdapter,
        useClass: MomentDateAdapter,
        deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
      },
      {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}, ]
})
export class SharedModule { }
