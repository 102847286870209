import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import {  ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { TrainerydataService } from 'src/app/services/trainerydata.service';
import { TrainerytempviewService } from 'src/app/services/trainerytempview.service';
import { environment } from 'src/environments/environment';
import { isDevMode } from '@angular/core';


@Component({
  selector: 'app-email-check',
  templateUrl: './email-check.component.html',
  styleUrls: ['./email-check.component.css']
})
export class EmailCheckComponent implements OnInit {
 
  environment=environment;


  constructor( private authService: AuthService,
    private router: Router,
    private errorHandler: ErrorHandlerService,
    private _snackBar: MatSnackBar,
    private trainery:TrainerytempviewService,
    private traineryData:TrainerydataService,
    private route:ActivatedRoute
   ) { }

    viewTrainery:boolean=true;
    email:string;
    mail=null;
    flag:number;
    noData:boolean=false;
    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    durationInSeconds = 3;
    urlLink:any;


  ngOnInit(): void {
    this.urlLink=this.route.snapshot.url[1].path;
    this.onInitLoginTrainery()
   
    

    
     
  }



//TRAINERY LOGIN
onInitLoginTrainery()
{

  if(environment.production=false)
  {
   this.viewTrainery=false;
  }
else
{
  this.authService.sso_auth_user().subscribe( async resData => {  
    if(Object.getOwnPropertyNames(resData).length !== 0) {
      localStorage.clear();

    let formsValue = {
      user_id: resData.user_id,
      organization: resData.organization.id,
      user: resData.id,
      username:resData.email,
      role: resData.roles[0].id,
      token: resData.token,
      roleName:resData.roles[0].key_name
    };

this.authService.ssoLoginAuth(formsValue).subscribe(res=>{

this.traineryData.setTraineryApplicationData(resData)  


localStorage.setItem('traineryView','true')

let rcRole = resData.roles.filter(role => role.applications.some(app => app.name.trim() === 'Review Cloud'));


let keyname=rcRole[0].key_name;



if(keyname=='client_admin'||keyname=='client_user')
{
   switch (this.urlLink) {
    case 'reviewsettings':
      this.router.navigate(['reviewrc3/rc3flowboard/flowboardmain/flowboard']);

      break;

      case 'historicaldata':
        this.router.navigate(['/history/rc3history/reports/feedbackreport']);

      break;

      case 'goalmanagement':
        this.router.navigate(['/rc-goal/goal-setting/dashboard']);

      break;

     case 'nineboxgrid':
      this.router.navigate(['/talentManagmentrc/nineboxgrid/dashboard'])
    break;

    case 'pip':
      this.router.navigate(['/rc-pip/pip-setting/builder/category'])
    break;

    case "idp":
       this.router.navigate(['/talentManagmentrc/idp-manager/list']);
      break;


   


    default:
      this.router.navigate(['/performancerc3/performancemanagement/performlist/dashboard']);
      break;
   }


}
else if(keyname=='tn_admin')
{
this.router.navigate(['reviewadminsection/adminreviewbuildersection/adminreviewbuilder/admincompetency']);
}


else
{
  this.errorHandler.showErrorMessageDialog('ERROR','Invalid User Role !!')
  localStorage.clear()
  window.location.href = environment.traineryUrlHome;
}

},
errorMessages=>{
  localStorage.clear()
  window.location.href = environment.traineryUrlHome;

}
)

  }
},
errorMessages=>{

  
  
  if(errorMessages.status==0)
  {
    
    this.router.navigate(['/error']);
   
  }
  if(environment.production_trainery==true)
  {
localStorage.clear()
window.location.href = environment.traineryUrlHome;
}
else
{
  this.viewTrainery=false;
  localStorage.setItem('traineryView','false')
}
  


}

)
}


}








  //LOGIN FORM DEFINE

  loginForm_check=new FormGroup({
    username: new FormControl('',Validators.required),
    password: new FormControl('',Validators.required),
  })


//LOGIN FORM SUBMIT FUNCTION
  onSumbit_check()
  {
    localStorage.clear()
    let username=this.loginForm_check.value.username;





     
    
    this.authService.ssoLoginAuths(username).subscribe(
      res=>{

        localStorage.setItem('traineryView','false')
        this.trainery.getTraineryAccess();
        this.trainery.getUserData();
          let data
          data=res.payload;
          if (data.user_type=='hr'){
            this.router.navigate(['/performancerc3/performancemanagement/performlist/dashboard']);

          }
          else if(data.user_type=='super_admin')
        {

          this.router.navigate(['reviewadminsection/adminreviewbuildersection/adminreviewbuilder/admincompetency']);

        }
        else
        {
          this.router.navigate(['/performancerc3/performancemanagement/performlist/dashboard']);


        }
      }
    )


  }

  openSnackBar() {
    this._snackBar.open('Invalid Login Credentials, Please try again!!', 'x', {
      horizontalPosition: this.horizontalPosition, 
      verticalPosition: this.verticalPosition,
      duration: this.durationInSeconds * 1000
    });
  }





//LOGIN FOR USERS
onSumbitOrg() {
  this.noData=true;


    if (this.loginForm_check.invalid) {
      this.noData=false;
     return;
   }
   
    // localStorage.removeItem('userToken');
    // localStorage.removeItem('userType');
    // localStorage.removeItem('userData');
    localStorage.clear();
    this.authService.login(this.loginForm_check.controls.username.value, this.loginForm_check.controls.password.value,'admin').subscribe(
      resData => {
        localStorage.setItem('traineryView','false')
        this.trainery.getTraineryAccess();
        this.trainery.getUserData();
        localStorage.setItem('traineryView','false')
       

        if(resData['payload'].user_type =='employee'){
          this.router.navigate(['/performancerc3/performancemanagement/performlist/dashboard']);
          
        }
       
        else if(resData['payload'].user_type =='hr'){
          this.router.navigate(['/performancerc3/performancemanagement/performlist/dashboard']);
          
        }
     
        
      },
      errorMessages => {
       
       
        this.noData=false;
       
      }
    ),errorMessages =>{
      this.errorHandler.showErrorDialog();
     
    }
  

}


//LOGIN FOR ADMIN PANEL
onSumbitAdmin() {
  this.noData=true;
  if (this.loginForm_check.invalid) {
     this.noData=false;
    return;
  }
  else{
   
    localStorage.removeItem('userToken');
    localStorage.removeItem('userType');
    localStorage.removeItem('userData');
    localStorage.clear();
 
    this.authService.rcAdminLogin(this.loginForm_check.value.username, this.loginForm_check.controls.password.value,'superadmin').subscribe(
      resData => {
       
        localStorage.setItem('traineryView','false')
        let data={
          "user_id": 3162,
          "email": "lijithca@yopmail.com",
          "role": 26,
          "full_name": "lijith CL",
          "profile_pic": null,
          "first_name": "lijith"
        }
    
        localStorage.setItem('user_data',JSON.stringify(data))
        this.router.navigate(['reviewadminsection/adminreviewbuildersection/adminreviewbuilder/admincompetency']);
   
      },
      errorMessages => {
     
        this.openSnackBar();
        this.noData=false;

      }
    );
    
  }

}


closeModal()
{
  document.getElementById('myModal').style.display='none'
}



}



