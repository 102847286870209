import { Injectable } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  ROUTE:string='';
  url:String=''
  constructor(private router:Router) { 

    
    

 


  }

  




  getRoute()
  {
    this.url=this.router.routerState.snapshot.url

    if(this.url.includes('pip'))
      {
        this.ROUTE='/performancerc3/pip-manager';
      }
      else if(this.url.includes('idp'))
      {
        this.ROUTE='/talentManagmentrc/idp-manager'
      }
      else
      {
         this.ROUTE='/talentManagmentrc/career-manager'
      }
    
      return this.ROUTE
  }

  routeType(url)
  {
    if(url.includes('pip'))
    {
      return 'PIP';
    }
    if(url.includes('idp'))
    {
      return 'IDP';
    }
    if(url.includes('career'))
      {
        return 'Career Builder';
      }

  }


}
