import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TrainerytempviewService {

  constructor() { }

  

   access= [
    {
        "name": "Organization",
        "status": true,
        "side_menu": [
            {
                "name": "",
                "status": true,
                "top_menu": [
                    {
                        "name": "PROFILE",
                        "status": true,
                        "sub_menu": [
                            {
                                "name": "PROFILES",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5637,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            },
                            {
                                "name": "SUBSCRIPTION",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5638,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            },
                            {
                                "name": "CONTACTS",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5641,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            },
                            {
                                "name": "STRUCTURE",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5639,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            },
                            {
                                "name": "LOCATIONS",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5640,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            },
                            {
                                "name": "PROFILE",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5659,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "SETTINGS",
                        "status": true,
                        "sub_menu": [
                            {
                                "name": "LOGO",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5642,
                                        "role": "RC Admin",
                                        "view": false,
                                        "update": false
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "name": "Employees",
        "status": true,
        "side_menu": [
            {
                "name": "",
                "status": true,
                "top_menu": [
                    {
                        "name": "SUMMARY",
                        "status": true,
                        "sub_menu": [
                            {
                                "name": "EMPLOYEES SUMMARY",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5643,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "UPLOAD MANAGER",
                        "status": true,
                        "sub_menu": [
                            {
                                "name": "USERS UPLOAD HISTORY SUMMARY",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5644,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            },
                            {
                                "name": "UPLOAD DATA SUMMARY",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5645,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "name": "Review Cloud",
        "status": true,
        "side_menu": [
            {
                "name": "Review Settings",
                "status": true,
                "top_menu": [
                    {
                        "name": "FLOWBOARD",
                        "status": true,
                        "sub_menu": [
                            {
                                "name": " ",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5634,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "CYCLE MANAGEMENT",
                        "status": true,
                        "sub_menu": [
                            {
                                "name": " ",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5635,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "TEMPLATES",
                        "status": true,
                        "sub_menu": [
                            {
                                "name": " ",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5646,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "REVIEW BUILDER",
                        "status": true,
                        "sub_menu": [
                            {
                                "name": "COMPETENCY",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5649,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            },
                            {
                                "name": "ELEMENTS",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5648,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            },
                            {
                                "name": "COMPETENCY LIBRARY",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5647,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            },
                            {
                                "name": "ELEMENT LIBRARY",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5647,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "name": "Performance Management",
                "status": true,
                "top_menu": [
                    {
                        "name": "PERFORMANCE",
                        "status": true,
                        "sub_menu": [
                            {
                                "name": "DASHBOARD",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5651,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            },
                            {
                                "name": "PERFORMANCE",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5651,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            },
                            {
                                "name": "360 FEEDBACK",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5650,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "REVIEWS",
                        "status": true,
                        "sub_menu": [
                            {
                                "name": "REVIEW",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5652,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            },
                            {
                                "name": "CHECK IN",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5653,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            },
                            {
                                "name": "360 FEEDBACK",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5654,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "PIP",
                        "status": true,
                        "sub_menu": [
                            
                         
                        ]
                    },

                ]
            },
               {
                "name": "Goal Management",
                "status": true,
                "top_menu": [
                    {
                        "name": "DASHBOARD",
                        "status": true,
                        "sub_menu": [
                            {
                                "name": "MY DASHBOARD",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5651,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            },
                            {
                                "name": "TEAM DASHBOARD",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5650,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            },
                            {
                                "name": "HR DASHBOARD",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5650,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "GOAL MANAGER",
                        "status": true,
                        "sub_menu": [
                            {
                                "name": "MY GOAL",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5652,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            },
                            {
                                "name": "TEAM GOALS",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5653,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            },
                         
                        ]
                    },
                    {
                        "name": "GOAL BUILDER",
                        "status": true,
                        "sub_menu": [
                            {
                                "name": "GOALBOARD",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5652,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            },
                            {
                                "name": "GOAL CYCLE SETTINGS",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5652,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            },
                            {
                                "name": "TEMPLATE",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5652,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            },
                            {
                                "name": "GOAL",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5653,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            },
                            {
                                "name": "GOAL CATEGORY",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5654,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "name": "Talent Management",
                "status": true,
                "top_menu": [
                    {
                        "name": "LEARNING PLAN",
                        "status": true,
                        "sub_menu": [
                            {
                                "name": "PARTICIPANTS/EMPLOYEES",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5651,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            },
                            {
                                "name": "ONLINE COURSES",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5650,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            },
                            {
                                "name": "LEARNING PROGRAMS",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5650,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "9 BOX GRID",
                        "status": true,
                        "sub_menu": [
                            {
                                "name": "GRIDBOARD",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5651,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            },
                            {
                                "name": "GRID ASSIGNMENT",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5651,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            },
                            {
                                "name": "GRID CYCLE",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5651,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            },
                            {
                                "name": "BOX SETTINGS",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5651,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            },
                            // {
                            //     "name": "COMPETENCY",
                            //     "status": true,
                            //     "role_access": [
                            //         {
                            //             "id": 5651,
                            //             "role": "RC Admin",
                            //             "view": true,
                            //             "update": true
                            //         }
                            //     ]
                            // },
                            // {
                            //     "name": "ELEMENT",
                            //     "status": true,
                            //     "role_access": [
                            //         {
                            //             "id": 5651,
                            //             "role": "RC Admin",
                            //             "view": true,
                            //             "update": true
                            //         }
                            //     ]
                            // },
                            // {
                            //     "name": "CATEGORY",
                            //     "status": true,
                            //     "role_access": [
                            //         {
                            //             "id": 5651,
                            //             "role": "RC Admin",
                            //             "view": true,
                            //             "update": true
                            //         }
                            //     ]
                            // },
                    
                        
                        ]
                    },
                    {
                        "name": "IDP",
                        "status": true,
                        "sub_menu": [
                            
                         
                        ]
                    },

                ]
            },{
                "name": "PIP",
                "status": true,
                "top_menu": [
                    {
                        "name": "DASHBOARD",
                        "status": true,
                        "sub_menu": [
                            {
                                "name": "MY DASHBOARD",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5651,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            },
                            {
                                "name": "TEAM DASHBOARD",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5650,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            },
                            {
                                "name": "HR DASHBOARD",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5650,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "MANAGER",
                        "status": true,
                        "sub_menu": [
                            {
                                "name": "MY GOAL",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5652,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            },
                            {
                                "name": "TEAM GOALS",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5653,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            },
                         
                        ]
                    },
                    {
                        "name": "BUILDER",
                        "status": true,
                        "sub_menu": [
                            {
                                "name": "FLOWBOARD",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5652,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            },
                            {
                                "name": "CYCLE",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5652,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            },
                            {
                                "name": "TEMPLATE",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5652,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            },
                            {
                                "name": "GOAL",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5653,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            },
                            {
                                "name": "CATEGORY",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5654,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "name": "Historical Data",
                "status": true,
                "top_menu": [
                    {
                        "name": "HISTORIC DATA",
                        "status": true,
                        "sub_menu": [
                            {
                                "name": "FEEDBACK",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5655,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            },
                            {
                                "name": "GOAL",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5655,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    }
]
data={
  "user_id": 3162,
  "email": "lijithca@yopmail.com",
  "role": 26,
  "full_name": "lijith CL",
  "profile_pic": null,
  "first_name": "lijith",
  "impersonate_acc":false,
  "fiscalEndMonth":6
}





  getTraineryAccess()
  {
    localStorage.setItem('access',JSON.stringify(this.access))
  }

getUserData()
{
  localStorage.setItem('user_data',JSON.stringify(this.data))

}





}
